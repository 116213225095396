@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  body {
      @apply bg-zinc-100
  }
  input {
    @apply focus-within:outline-none
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input {
    -webkit-border-radius: 0;
    -webkit-appearance: none;
  }

  button {
    @apply active:opacity-60
  }
}
